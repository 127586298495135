import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Router } from 'preact-router';
import './style';
import { GlobalContext } from './context/global';
import Home from './routes/home';
import Testimonial from './routes/testimonial';
import Picture from './routes/picture';
import Entry from './routes/entry';
import Share from './routes/share';
import View from './routes/view';

export default function App() {
	const globalState = useState({
		testimonial: null,
		image: null,
	});

	return (
		<div class="wrapper">
			<div class="wrapper-inner">
				<GlobalContext.Provider value={globalState}>
					<Router>
						<Home path="/" />
						<Testimonial path="/testimonial" />
						<Picture path="/picture" />
						<Entry path="/entry" />
						<Share path="/share/:submissionId" />
						<View path="/view/:submissionId" />
					</Router>
				</GlobalContext.Provider>
			</div>
		</div>

	);
}
